import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Navbar from "src/components/Navbar/Navbar";
import Callback from "src/pages/Callback";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { ProtectedRoutes } from "./protected-routes";
import { lazy, Suspense } from "react";
const SetAccount = lazy(() => import("src/pages/Account/SetUpAccount"));
const BindAccount = lazy(() => import("src/pages/Account/BindAccount"));
const RequestAccount = lazy(() => import("src/pages/Account/RequestAccount"));
const ServerErr = lazy(() => import("src/pages/Error/500"));
const PageNotFound = lazy(() => import("src/pages/Error/404"));
export const routes: RouteObject[] = [
  {
    element: (
      <>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            className: "mt-5",
            duration: 1800,
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          }}
        />
        {/* <Navbar /> */}
        <AuthenticatedTemplate>
          <Suspense>
            <Outlet />
          </Suspense>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Callback />
        </UnauthenticatedTemplate>
      </>
    ),

    children: [
      ...ProtectedRoutes,
      {},
      {
        path: "set-account",
        element: <SetAccount />,
      },
      {
        path: "bind-account",
        element: <BindAccount />,
      },
      {
        path: "request-account",
        element: <RequestAccount />,
      },
      {
        path: "500",
        element: <ServerErr />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
];
