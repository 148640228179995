import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import type { Theme } from "@mui/material";
import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { NavColor } from "src/types/settings";
import type { Section } from "../config";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import Navbar from "src/components/Navbar/Navbar";
import { useMobileNav } from "./use-mobile-nav";

const SIDE_NAV_WIDTH: number = 280;

const VerticalLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  height: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  overflowY: "scroll",
});

interface VerticalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: Section[];
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const mobileNav = useMobileNav();

  return (
    <>
      {/* <TopNav onMobileNavOpen={mobileNav.handleOpen} /> */}
      <Navbar
        onSideNavOpen={mobileNav.handleOpen}
        sideNav={lgUp ? SIDE_NAV_WIDTH : null}
      />
      {lgUp && <SideNav color={navColor} sections={sections} />}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <VerticalLayoutRoot>
        <VerticalLayoutContainer className="scrollbar-hide">{children}</VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};
