import { useState, type ChangeEvent, type FC, type MouseEvent } from "react";
import numeral from "numeral";
import PropTypes from "prop-types";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import {
  Box,
  Chip,
  IconButton,
  Link,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { Scrollbar } from "src/components/scrollbar";
import { paths } from "src/paths";

import { GliderTask, TaskResourceSpec } from "src/interfaces/Tasks";
import { dateReadableFormat } from "src/utils/date-format";
import { SeverityPill, SeverityPillColor } from "src/components/severity-pill";
import { TaskStatus } from "@apex-portal/shared-types";

interface TaskListTableProps {
  count?: number;
  items?: GliderTask[];
  onPageChange?: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  page?: number;
  rowsPerPage?: number;
}

const statusMap: Record<TaskStatus, SeverityPillColor> = {
  Succeeded: "success",
  Running: "info",
  Pending: "warning",
};

export const TaskListTable: FC<TaskListTableProps> = (props) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
  } = props;


  return (
    <Box sx={{ position: "relative" }}>
      <Scrollbar>
        <Table
          sx={{
            minWidth: 700,
            "& thead": {},
            "& thead th": {
              color: "#000000",
              fontWeight: "bold",
              backgroundColor: "!important",
              border: "none",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Namespace</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Time Used (sec)</TableCell>
              <TableCell>Started</TableCell>
              <TableCell>Ended</TableCell>
              <TableCell>Allocated Resources</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((task) => {
              let resource = JSON.parse(task.resAlloc) as TaskResourceSpec;
              const statusColor = statusMap[task.status] || "error";

              return (
                <TableRow hover key={task.projectId + task.name}>
                  <TableCell>
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <Typography>{task.name}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{task.namespace}</TableCell>
                  <TableCell>{task.project}</TableCell>
                  <TableCell>
                    <SeverityPill color={statusColor}>
                      {task.status}
                    </SeverityPill>
                  </TableCell>
                  <TableCell>
                    {task.status === "Succeeded"
                      ? task.timeEnd - task.timeStart
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {task.timeStart > 0
                      ? dateReadableFormat(
                          new Date(task!.timeStart * 1000).toISOString()
                        )
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {task.timeStart > 0
                      ? dateReadableFormat(
                          new Date(task!.timeStart * 1000).toISOString()
                        )
                      : "-"}
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2">
                      <Chip
                        label={
                          resource.cpu_count
                            ? `CPU : ${resource.cpu_count}`
                            : "-"
                        }
                      />
                      <Chip
                        label={
                          resource.gpu_count
                            ? `GPU : ${resource.gpu_count}`
                            : "-"
                        }
                      />
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton component={RouterLink} href={"/project/" + task.projectId}> 
                      <SvgIcon>
                        <ArrowRightIcon />
                      </SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Box>
  );
};

TaskListTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};
