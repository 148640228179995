import { axiosClient as axios } from "src/api/API";
import { API, createEndpoint } from "src/api/API";

let { endpoint } = API
const shootAPI = async () => {
    try {
      let res = await axios.get(createEndpoint(endpoint.testToken))
      let data = res.data;
      console.log(data);
    } catch (error) {
      console.log(error);
    }
};
const ForTokenTesting = () => {
  return (
    <button
      onClick={() => shootAPI()}
      className="bg-white w-[100px] text-black mt-20 m-3 p-3"
    >
      Shoot API
    </button>
  );
};

export default ForTokenTesting;
