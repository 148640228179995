import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardActions,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useThemeOptions = (): Record<string, string> => {
  const theme = useTheme();
    return {
      // "backgroundColor": "#131820f7",
      "width": "500px"
    };
};

interface AnalyticsStatsProps {
  title: string;
  price: number;
  unit_used: number;
  cost: number
  img_source: string
}

export const AnalyticsStats: FC<AnalyticsStatsProps> = (props) => {
  const { price, title, unit_used, cost, img_source } = props;
  const themeOptions = useThemeOptions();

  return (
    <Card sx={{...themeOptions}}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          py: 2,
          width: 'full'
        }}
      >
        <div>
          <Typography color="text.secondary" variant="h4">
            {title}
          </Typography>
          <Typography sx={{ mt: 1 }} variant="body2">
            {price} THB/Min.
          </Typography>
          <Typography sx={{ mt: 1 }} variant="body1">
            {unit_used} unit used
          </Typography>
        </div>

        <div>
          <img src={img_source} width={100} />
        </div>
      </Stack>
      <Divider />
      <CardActions sx={{...themeOptions}}>
        <div className="mx-4 my-1 gap-4 flex justify-between items-end">
          <Typography sx={{fontSize: 20, fontWeight: 'light'}}>
            Cost
          </Typography>
          <Typography sx={{fontSize: 20, fontWeight: 'medium'}}>
            {cost} THB.
          </Typography>
        </div>
      </CardActions>
    </Card>
  );
};

AnalyticsStats.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  unit_used: PropTypes.number.isRequired,
  cost: PropTypes.number.isRequired,
  img_source: PropTypes.string.isRequired,
};
