import "./App.css";
import { Routes, Route, useRoutes } from "react-router-dom";
import { routes } from "./routes";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "./theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const queryClient = new QueryClient();
  const theme = createTheme({
    colorPreset: "indigo",
    paletteMode: "dark",
  });
  const element = useRoutes(routes);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
