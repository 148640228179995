import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { API, createEndpoint, axiosClient as axios } from 'src/api/API';
import { getResourceUsageCurrentMouth } from 'src/api/dashboard';
import { endpoints } from 'src/api/user-project';
import { DataUsage, UserUsageMonthQueryParams, UserUsageQueryParams } from 'src/interfaces/UsageData';

export const QueryCurrentMonthUsage = (queryKey: string, userIdQuery: UserUsageQueryParams) => {
    return useQuery({
        queryKey: [queryKey, userIdQuery.uid, userIdQuery.ad_id], 
        queryFn: GetCurrentMonthUsage
    })
}

let { endpoint } = API
const GetCurrentMonthUsage = async ({queryKey} : {queryKey: [string, string, string]}): Promise<DataUsage> => {
    const [, user_apex_id, entra_id] = queryKey
    let res = await axios.get(createEndpoint(endpoint.userUsages), {
        params: {
            uid: user_apex_id,
            ad_id: entra_id,
        }
    })
    return res.data
}

export const QuerySpecificMonthUsage = (queryKey: string, userQuery: UserUsageMonthQueryParams) => {
    return useQuery({
        queryKey: [queryKey, userQuery.uid, userQuery.ad_id, userQuery.month.toString(), userQuery.year.toString()],
        queryFn: GetSpecificMonthUsage,
        
    })
}

const GetSpecificMonthUsage = async({queryKey} : {queryKey: [string, string, string, string, string]}): Promise<DataUsage> => {
    const [, user_apex_id, user_entra_id, month, year] = queryKey
    let res = await axios.get(createEndpoint(endpoint.userUsagesPermonth), {
        params: {
            uid: user_apex_id,
            ad_id: user_entra_id,
            year: year,
            month: month
        }
    })
    return res.data
}