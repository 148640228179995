import type { Page as PageType } from "../../types/page";
import React, {
  useCallback,
  useEffect,
  useState,
  MouseEvent,
  ChangeEvent,
  useMemo,
} from "react";
import Loading from "../../components/Loading";
import { DashboardCPUUsage } from "../../components/Dashboard/re-design/dashboard-cpu-usage";
import { AnalyticsStats } from "src/components/Dashboard/re-design/analytics-stats";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Container,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import { useUserContext } from "src/contexts/ApexUserContext";
import { UserContextType } from "src/types/userContextType";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  QueryCurrentMonthUsage,
  QuerySpecificMonthUsage,
} from "src/hooks/resource_usage_summary/resource_usage_summary";
import { useNavigate } from "react-router-dom";
import { DataUsage } from "src/interfaces/UsageData";
import { GliderTask, TaskStatus } from "src/interfaces/Tasks";
import { useMounted } from "src/hooks/use-mounted";
import { TaskListSearch } from "src/components/Dashboard/re-design/task-list-search";
import { ItemSearch } from "src/components/Project/item-search";
import { TaskListTable } from "src/components/Dashboard/re-design/task-list-table";
import RefreshIcon from "@untitled-ui/icons-react/build/esm/RefreshCcw02";

import MonthYearPicker from "src/components/Dashboard/re-design/calendar";
import KubeTasksTable from "src/components/Dashboard/customs/KubeTasksTable";

interface Filters {
  query?: string;
  Glider?: boolean;
  Slurm?: boolean;
}

interface ItemsSearchState {
  filters: Filters;
  page: number;
  rowsPerPage: number;
  sortBy: string;
  sortDir: "asc" | "desc";
}

const useItemsSearch = () => {
  const [state, setState] = useState<ItemsSearchState>({
    filters: {
      query: undefined,
      Glider: undefined,
      Slurm: undefined,
    },
    page: 0,
    rowsPerPage: 5,
    sortBy: "createdAt",
    sortDir: "desc",
  });

  const handleFiltersChange = useCallback((filters: Filters): void => {
    setState((prevState) => ({
      ...prevState,
      filters,
    }));
  }, []);

  const handleSortChange = useCallback(
    (sort: { sortBy: string; sortDir: "asc" | "desc" }): void => {
      setState((prevState) => ({
        ...prevState,
        sortBy: sort.sortBy,
        sortDir: sort.sortDir,
      }));
    },
    []
  );

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
      setState((prevState) => ({
        ...prevState,
        page,
      }));
    },
    []
  );

  const handleRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setState((prevState) => ({
        ...prevState,
        rowsPerPage: parseInt(event.target.value, 10),
      }));
    },
    []
  );

  return {
    handleFiltersChange,
    handleSortChange,
    handlePageChange,
    handleRowsPerPageChange,
    state,
  };
};

export interface ResourceUsageState {
  CPUTime: number;
  GPUTime: number;
  CPUCost: number;
  GPUCost: number;
  TotalTask: number;
  Storage: number;
  StorageCost: number;
  TaskList: any[];
  KubeTaskList: KubeTaskList[];
}

export interface KubeTaskList {
  create: string;
  name: string;
  namespace: string;
  project: string;
  projectId: string;
  resAlloc: string;
  status: TaskStatus;
  timeEnd: number;
  timeStart: number;
}

interface DisplayTaskState {
  TotalTask: number;
  DisplayTask: KubeTaskList[];
}

const useItemsStore = (searchState: ItemsSearchState, queryDate: date) => {
  const isMounted = useMounted();
  const [items, setItems] = useState<ResourceUsageState>({
    CPUTime: 0,
    GPUTime: 0,
    CPUCost: 0,
    GPUCost: 0,
    TotalTask: 0,
    Storage: 0,
    StorageCost: 0,
    TaskList: [],
    KubeTaskList: [],
  });

  const [taskDone, setTaskDone] = useState<number>(0);

  const [displayItem, setDisplayItem] = useState<DisplayTaskState>({
    TotalTask: 0,
    DisplayTask: [],
  });

  const queryClient = useQueryClient();
  let { user } = useUserContext() as UserContextType;
  const {
    data: dataUsage,
    error,
    isError,
    isLoading,
    isPending,
    refetch,
  } = QuerySpecificMonthUsage("ResourceUsage", {
    uid: user?.apex_id || "",
    ad_id: user?.ad_id as string,
    month: queryDate.month!,
    year: queryDate.year!,
  });

  useEffect(() => {
    if (isMounted() && dataUsage) {
      setItems({
        CPUTime: dataUsage.CPUTime,
        GPUTime: dataUsage.CPUTime,
        CPUCost: dataUsage.CPUCost,
        GPUCost: dataUsage.GPUCost,
        TotalTask: dataUsage.TotalTask,
        Storage: dataUsage.Storage,
        StorageCost: dataUsage.StorageCost,
        TaskList: dataUsage.TaskList,
        KubeTaskList: dataUsage.KubeTaskList,
      });

      setTaskDone(
        () =>
          dataUsage.KubeTaskList.filter((task) => task.status === "Succeeded")
            .length
      );

      let displayData: KubeTaskList[] = [];

      if (
        searchState.filters.query === undefined &&
        searchState.filters.Slurm === undefined &&
        searchState.filters.Glider === undefined
      ) {
        displayData = dataUsage.KubeTaskList;
        console.log("ALL : ", displayData);

        // displayData.push(dataUsage.TaskList)
      }

      displayData = displayData.sort((a, b) => {
        const dateA = new Date(a.create).getTime();
        const dateB = new Date(b.create).getTime();
        return searchState.sortDir === "asc" ? dateA - dateB : dateB - dateA;
      });

      const itemStart = searchState.page * searchState.rowsPerPage;
      const itemEnd = itemStart + searchState.rowsPerPage;

      displayData = displayData.slice(itemStart, itemEnd);

      setDisplayItem({
        TotalTask: displayData.length,
        DisplayTask: displayData,
      });
    }
  }, [dataUsage]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["ResourceUsage"] });
    // console.log(searchState);

    let displayData: KubeTaskList[] = [];

    if (
      searchState.filters.query === undefined &&
      searchState.filters.Slurm === undefined &&
      searchState.filters.Glider === undefined
    ) {
      displayData = items.KubeTaskList;
      // console.log("ALL : ", displayData);

      // displayData.push(items.TaskList)
    }

    if (searchState.filters.query !== undefined) {
      const lowerCaseQuery = searchState.filters.query.toLowerCase();
      displayData = items.KubeTaskList.filter((task) =>
        task.name.toLowerCase().includes(lowerCaseQuery)
      );
      // console.log("query : ", displayData)
    } else if (searchState.filters.Slurm !== undefined) {
      // set Slerm data
      // displayData = items.TaskList
      // console.log("Slurm : ", displayData)
    } else if (searchState.filters.Glider !== undefined) {
      displayData = items.KubeTaskList;
      // console.log('display Glider : ',displayData);
    }

    displayData = displayData.sort((a, b) => {
      const dateA = new Date(a.create).getTime();
      const dateB = new Date(b.create).getTime();
      return searchState.sortDir === "asc" ? dateA - dateB : dateB - dateA;
    });

    const itemStart = searchState.page * searchState.rowsPerPage;
    const itemEnd = itemStart + searchState.rowsPerPage;

    displayData = displayData.slice(itemStart, itemEnd);

    setDisplayItem({
      TotalTask: displayData.length,
      DisplayTask: displayData,
    });
  }, [searchState]);

  useEffect(() => {
    // console.log(displayItem.DisplayTask);
  }, [displayItem]);
  return {
    ...items,
    setItems,
    user,
    isLoading,
    isError,
    refetch,
    displayItem,
    taskDone,
  };
};

export type date = {
  month: number | undefined;
  year: number | undefined;
};

const useHandleDateChange = () => {
  const currentTime = new Date();

  const [selectedDate, setSelectedDate] = useState<date>({
    month: currentTime.getMonth() + 1,
    year: currentTime.getFullYear(),
  });

  const handleDateChange = useCallback((newDate: date): void => {
    setSelectedDate(newDate);
  }, []);

  return { selectedDate, handleDateChange };
};

const Home: PageType = () => {
  const itemSearch = useItemsSearch();
  const handleDate = useHandleDateChange();
  const itemStore = useItemsStore(itemSearch.state, handleDate.selectedDate);

  const handleRefresh = () => {
    itemStore.refetch();
  };

  let navigator = useNavigate();

  if (itemStore.isLoading) {
    // return <Loading/>
  }

  if (itemStore.isError) {
    navigator("/500");
  }

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          mt: 10,
        }}
      >
        <Container maxWidth="xl">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={4}
            sx={{ mt: 4, mb: 3 }}
          >
            <Typography variant="h3">Overview</Typography>
            <MonthYearPicker onDateChange={handleDate.handleDateChange} />
          </Stack>
          <Stack maxWidth="xl" direction="row" spacing={4}>
            <AnalyticsStats
              title="CPU"
              price={0.01}
              unit_used={itemStore.CPUTime}
              cost={itemStore.CPUCost}
              img_source="\assets\computer\processor.png"
            />
            <AnalyticsStats
              title="GPU"
              price={0.01}
              unit_used={itemStore.GPUCost}
              cost={itemStore.GPUCost}
              img_source="\assets\computer\video-card.png"
            />
            <AnalyticsStats
              title="Storage"
              price={0.01}
              unit_used={itemStore.Storage}
              cost={itemStore.StorageCost}
              img_source="\assets\computer\hard-disk.png"
            />
          </Stack>
        </Container>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          mt: 5,
          mb: 5,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ alignItems: "center" }}
            >
              <Stack direction="column" justifyContent="flex-start" spacing={0}>
                <Typography variant="h4" color="text.primary">
                  Tasks
                </Typography>

                <Typography variant="subtitle1" color="text.primary">
                  Completed Tasks : {itemStore.taskDone}
                </Typography>
              </Stack>
              <Button
                startIcon={
                  <SvgIcon>
                    <RefreshIcon />
                  </SvgIcon>
                }
                variant="contained"
                sx={{ height: 1 / 2 }}
                onClick={handleRefresh}
              >
                Refresh
              </Button>
            </Stack>
            <Card>
              <TaskListSearch
                onFiltersChange={itemSearch.handleFiltersChange}
                onSortChange={itemSearch.handleSortChange}
                sortBy={itemSearch.state.sortBy}
                sortDir={itemSearch.state.sortDir}
              />
              <TaskListTable
                count={itemStore.KubeTaskList.length}
                items={itemStore.displayItem.DisplayTask}
                onPageChange={itemSearch.handlePageChange}
                onRowsPerPageChange={itemSearch.handleRowsPerPageChange}
                page={itemSearch.state.page}
                rowsPerPage={itemSearch.state.rowsPerPage}
              />
            </Card>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Home;
