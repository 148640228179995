import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";
import Dashboard from "src/pages/Dashboard";
import { Layout } from "src/layout/dashboard";
import ProtectedRoute from "src/authentication/protectedRoute";
import { paths } from "src/paths";
import ForTokenTesting from "src/pages/testing";
import Home from "src/pages/Home";
const UserProject = lazy(() => import("src/pages/Projects"));
const ProjectInfo = lazy(() => import("src/pages/ProjectInfo"));
export const ProtectedRoutes: RouteObject[] = [
  {
    path: "/",
    element: (
      <Layout>
        <Suspense>
          <ProtectedRoute />
        </Suspense>
      </Layout>
    ),
    children: [
      {
        index: true,
        element: <Home/>,
      },
      {
        path: paths.project.index,
        element: <UserProject />,
      },
      {
        path: paths.project.info,
        element: <ProjectInfo />,
      },
      {
        path: paths.tokentesting.index,
        element: <ForTokenTesting />
      },
    ],
  },
];
