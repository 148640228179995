import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import { useUserContext } from "../../contexts/ApexUserContext";
import "./style.css";
import { UserContextType } from "../../types/userContextType";
import { isAdmin } from "../../authentication/validateGroup";
import { useEffect, useState } from "react";
import { IconButton, SvgIcon } from "@mui/material";
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';

const pageLink = {
  projects: "/projects",
};

const Navbar = ({
  sideNav,
  onSideNavOpen,
}: {
  sideNav?: number | null;
  onSideNavOpen: () => void;
}) => {
  let { accounts, instance } = useMsal();
  let { user } = useUserContext() as UserContextType;
  let [userInfo, setuserInfo] = useState<any>({ name: "", username: "" });
  const ipa_host = process.env.REACT_APP_IPA;
  useEffect(() => {
    if (instance.getActiveAccount()) {
      let { name, username } = instance.getActiveAccount()!;
      setuserInfo({ name, username });
    }
  }, [user]);

  return (
    <div
      className="navbar right-0 backdrop-blur-md"
      style={{ width: sideNav ? `calc(100% - ${sideNav}px)` : "100%" }}
    >
      <div id="nav-left-box">
        {!sideNav && (
            <IconButton onClick={onSideNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
        )}
      </div>
      <div id="nav-right-box" className="flex flex-row-reverse gap-x-2">
        {accounts[0]?.localAccountId && (
          <div className="flex flex-col justify-center items-center relative  space-x-3 ">
            <button
              type="button"
              className="flex text-sm bg-gray-800 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
              id="user-menu-button"
              onClick={() => {
                let dropdown = document.getElementById("user-dropdown");
                let dropdownState = dropdown?.style.display;
                if (dropdownState === "block") {
                  dropdown!.style.display = "none";
                } else {
                  dropdown!.style.display = "block";
                  dropdown!.focus();
                }
              }}
            >
              <span className="sr-only">Open user menu</span>
              <img
                className="w-12 h-12 rounded-full"
                src="\assets\images.png"
                alt="user photo"
              />
            </button>
            <div
              className="absolute right-0  top-[100%] z-50 hidden my-2 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
              id="user-dropdown"
              onMouseLeave={(e) => {
                document.getElementById("user-dropdown")!.style.display =
                  "none";
              }}
            >
              <div className="px-4 py-3">
                <span className="block text-sm text-gray-900 dark:text-white">
                  {userInfo.name}
                </span>
                <span className="block text-sm  text-gray-500 truncate dark:text-gray-400">
                  {userInfo.username}
                </span>
              </div>
              <ul className="py-2" aria-labelledby="user-menu-button">
                <li>
                  <Link
                    to="/"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    Dashboard
                  </Link>
                </li>
                {/* <li>
                    <Link
                      to="setting"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >
                      Settings
                    </Link>
                  </li> */}

                <li>
                  <a
                    onClick={(e) => {
                      instance.logoutRedirect();
                    }}
                    className="block w-full  px-4 py-2 text-sm text-white hover:bg-gray-100 dark:hover:bg-gray-600 "
                  >
                    Sign out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}

        {/* {isAdmin(user?.groups) && (
          <Link className="nav-btn" to="admin">
            Admin
          </Link>
        )}
        <Link className="nav-btn" to={pageLink.projects}>
          Projects
        </Link> */}
      </div>
    </div>
  );
};

export default Navbar;
