import React, { FC, useCallback, useState } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, Box, Typography, IconButton, Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { date } from "src/pages/Home";
import RestoreIcon from "@mui/icons-material/Restore";

interface MonthYearPickerProp {
  onDateChange?: (newDate: date) => void;
}

const MonthYearPicker: FC<MonthYearPickerProp> = (props) => {
  const { onDateChange } = props;
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

  //   const handleDateChange = (newDate: Dayjs | null) => {
  //     setSelectedDate(newDate);
  //     if (newDate) {
  //       console.log("Selected Month:", newDate.month() + 1); // month() is 0-indexed
  //       console.log("Selected Year:", newDate.year());
  //     }
  //   };

  const handleDateChange = useCallback(
    (newDate: Dayjs | null): void => {
      setSelectedDate(newDate);

      onDateChange?.({
        month: (newDate?.month() || 0) + 1,
        year: newDate?.year(),
      });
    },
    [onDateChange]
  );

  const handleDateReset = useCallback((): void => {
    const dayjsDate: Dayjs = dayjs(new Date());

    setSelectedDate(dayjsDate);
    // console.log("Reset ", dayjsDate?.month(), dayjsDate?.year());

    onDateChange?.({
      month: (dayjsDate?.month() || 0) + 1,
      year: dayjsDate?.year(),
    });
  }, [onDateChange]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton size="large" color="primary" onClick={handleDateReset}>
            <RestoreIcon fontSize="inherit" />
          </IconButton>
          <DatePicker
            views={["year", "month"]}
            label="Data From"
            value={selectedDate}
            onChange={handleDateChange}
            slotProps={{ textField: { helperText: null } }}
          />
        </Stack>
      </Box>
    </LocalizationProvider>
  );
};

export default MonthYearPicker;
