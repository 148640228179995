export const paths = {
    index: '/',
    auth: {
        index: "/login"
    },
    dashboard: {
        index: '/',
    }, project: {
        index: '/projects',
        info:'/project/:id',
        tasks:'/project/:id/tasks'
    }, tokentesting: {
        index: '/token'
    }, home: {
        index: '/home'
    },
    401: '/401',
    404: '/404',
    500: '/500'
};
