import type { FC } from 'react';
import { ChangeEvent, FormEvent, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import {
  Box,
  Divider,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  TextField
} from '@mui/material';
import { useUpdateEffect } from 'src/hooks/use-update-effect';

interface Filters {
  query?: string;
  Glider?: boolean;
  Slurm?: boolean;
}

type TabValue = 'all' | 'Glider' | 'Slurm';

interface TabOption {
  label: string;
  value: TabValue;
}

const tabs: TabOption[] = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Glider',
    value: 'Glider'
  },
  {
    label: 'Slurm',
    value: 'Slurm'
  },
];

type SortValue = 'CreateAt|desc' | 'CreateAt|asc';
type StatusValue = 'Running' | 'Succeeded';
interface SortOption {
  label: string;
  value: SortValue;
}

interface statusOptions{
    label: string;
    value: StatusValue;
}

const sortOptions: SortOption[] = [
  {
    label: 'Create Time (newest)',
    value: 'CreateAt|desc'
  },
  {
    label: 'Create Time (oldest)',
    value: 'CreateAt|asc'
  },
];

const statusOptions: statusOptions[] = [
    {
        label: 'Running',
        value: 'Running',
    },
    {
        label: 'Succeeded',
        value: 'Succeeded'
    }
]

type SortDir = 'asc' | 'desc';

interface TaskListSearchProps {
  onFiltersChange?: (filters: Filters) => void;
  onSortChange?: (sort: { sortBy: string; sortDir: SortDir }) => void;
  sortBy?: string;
  sortDir?: SortDir;
}

export const TaskListSearch: FC<TaskListSearchProps> = (props) => {
  const { onFiltersChange, onSortChange, sortBy, sortDir } = props;
  const queryRef = useRef<HTMLInputElement | null>(null);
  const [currentTab, setCurrentTab] = useState<TabValue>('all');
  const [filters, setFilters] = useState<Filters>({});

  const handleFiltersUpdate = useCallback(
    () => {
      onFiltersChange?.(filters);
    },
    [filters, onFiltersChange]
  );

  useUpdateEffect(
    () => {
      handleFiltersUpdate();
    },
    [filters, handleFiltersUpdate]
  );

  const handleTabsChange = useCallback(
    (event: ChangeEvent<{}>, value: TabValue): void => {
      setCurrentTab(value);
      setFilters((prevState: any) => {
        const updatedFilters: Filters = {
          ...prevState,
          Glider: undefined,
          Slurm: undefined,
        };

        if (value !== 'all') {
          updatedFilters[value] = true;
        }

        return updatedFilters;
      });
    },
    []
  );

  const handleQueryChange = useCallback(
    (event: FormEvent<HTMLFormElement>): void => {
      event.preventDefault();

      const reset = undefined

      if(queryRef.current?.value == ""){
        setFilters((prevState: any) => ({
          ...prevState,
          query: undefined
        }));
      }
      else {
        setFilters((prevState: any) => ({
          ...prevState,
          query: queryRef.current?.value
        }));
      }

    },
    []
  );

  const handleSortChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const [sortBy, sortDir] = event.target.value.split('|') as [string, SortDir];

      onSortChange?.({
        sortBy,
        sortDir
      });
    },
    [onSortChange]
  );

  return (
    <>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        sx={{ px: 3}}
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        spacing={3}
        sx={{ p: 3 }}
      >
        <Box
          component="form"
          onSubmit={handleQueryChange}
          sx={{ flexGrow: 1 }}
        >
          <OutlinedInput
            defaultValue=""
            fullWidth
            inputProps={{ ref: queryRef }}
            placeholder="Search Task's name"
            startAdornment={(
              <InputAdornment position="start">
                <SvgIcon>
                  <SearchMdIcon />
                </SvgIcon>
              </InputAdornment>
            )}
          />
        </Box>
        <TextField
          label="Sort By"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={`${sortBy}|${sortDir}`}
        >
          {sortOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Stack>
    </>
  );
};

TaskListSearch.propTypes = {
  onFiltersChange: PropTypes.func,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDir: PropTypes.oneOf<SortDir>(['asc', 'desc'])
};
