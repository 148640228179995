import { ReactElement, ReactNode, useEffect } from "react";
import { checkUser } from "../api/user";
import { useMsal, MsalAuthenticationResult } from "@azure/msal-react";
import { useUserContext } from "../contexts/ApexUserContext";
import { UserContextType } from "../types/userContextType";
import { Navigate, Outlet, OutletProps, useNavigate } from "react-router-dom";
import { JSX } from "react/jsx-runtime";
import Loading from "../components/Loading";
import { setReqToken } from "src/api/API";
const ProtectedRoute = (
  props: JSX.IntrinsicAttributes & OutletProps
): React.ReactElement | null => {
  let { accounts, instance } = useMsal();
  let { user, updateUser } = useUserContext() as UserContextType;
  let navigation = useNavigate();
  let API_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID
  useEffect(() => {
    if (accounts[0]) {
      instance
        .acquireTokenSilent({
          scopes: [`api://${API_CLIENT_ID}/access_as_user`],
          account: accounts[0],
        })
        .then((res) => {
          instance.setActiveAccount(accounts[0]);
          let userGroup = (res.idTokenClaims as { [key: string]: any }).groups;
          let token = res.accessToken;
          console.log(res);
          
          setReqToken(token);
          if (res.uniqueId) {
            checkUser(res.uniqueId).then((res) => {
              if (res.err) {
                navigation("500");
              } else {
                if (res?.validUser) {
                  updateUser({
                    ...res?.data,
                    groups: userGroup,
                    access_token: token,
                  });
                } else {
                  navigation("set-account");
                }
              }
            });
          }
        });
    }
  }, [accounts]);

  return user ? <Outlet {...props} /> : <Loading />;
};
export default ProtectedRoute;
